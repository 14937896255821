import {Injectable} from '@angular/core';
import {TASK_TYPE_DEFINITIONS, TaskTypeDefinition, TaskTypeField} from './api.task_types';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {take} from 'rxjs/operators';
import {FormGroupService} from './forms/form-group.service';
import {TaskType, TaskTypeFlattened, TaskTypeMerged, TaskTypesOutput} from './ccm.service';
import {APIService, BillingCodeCategorySerializer} from './api.service';
import {User} from '../models/user';
import {UserAuthService} from './user-auth.service';
import {FollowingService} from './following.service';
import {TzDatePipe} from '../@theme/pipes/tz-date.pipe';
import {FilterService} from './filter.service';

@Injectable({
    providedIn: 'root',
})
export class TaskTypeService {
    billingCategories$ = new ReplaySubject<BillingCodeCategorySerializer[]>(1);
    taskTypesFlattened$ = new ReplaySubject<TaskTypeFlattened[]>(1);
    taskTypesMerged$ = new ReplaySubject<TaskTypeMerged[]>(1);
    taskTypesMergedOptions$ = new ReplaySubject<TaskTypeMerged[]>(1);
    taskTypesMergedOptionsWAcute$ = new ReplaySubject<TaskTypeMerged[]>(1);
    taskBlocks$ = new ReplaySubject<TaskTypeField[]>(1);
    taskTypesFlattened: TaskTypeFlattened[];
    taskTypesMerged: TaskTypeMerged[];
    taskTypesMergedOptions: TaskTypeMerged[];
    taskTypesMergedOptionsWAcute: TaskTypeMerged[];
    taskDynamicFields: string[];
    private _user: User;

    constructor(private api: APIService,
                private userAuth: UserAuthService,
                private followingService: FollowingService,
                private tzDate: TzDatePipe,
                private filterService: FilterService) {
        this.userAuth.user.subscribe(u => {
            this._user = u;
        });
    }

    getTaskTypes(): Observable<TaskTypesOutput> {
        const obs$ = new Subject<TaskTypesOutput>();
        this.billingCategories$.pipe(take(1)).subscribe(bcc => {
            const res = TASK_TYPE_DEFINITIONS;
            const output: TaskTypesOutput = {};
            const blocks: TaskTypeField[] = [];

            this.taskTypesFlattened = [];
            const processFlattenedCategory = (node: Partial<TaskType>, bcc, level = 0, inheritedRoles = null, inheritedDuration = null, inheritedFax = false, inheritedSms = false, inheritedBC = null, inheritedBPText = null, inheritedNPText = null) => {
                node.implicitRoles = node.roles || inheritedRoles;
                node.implicitDuration = typeof node.default_duration === 'number' ? node.default_duration : inheritedDuration;
                node.implicitFax = node.need_to_fax ? node.need_to_fax : inheritedFax;
                node.implicitSms = node.need_to_sms ? node.need_to_sms : inheritedSms;
                node.implicitBillingCategory = node.billing_code_category ? bcc.find(billingCodeCategory => node.billing_code_category === billingCodeCategory.id) : inheritedBC;
                node.implicitBillingPlaceholderText = node.placeholder_billing_description ? node.placeholder_billing_description : inheritedBPText;
                node.implicitBillingNotePlaceholderText = node.placeholder_note_description ? node.placeholder_note_description : inheritedNPText;

                const extraFields = node.is_visit && node.extra_fields && node.extra_fields.length ?
                    node.extra_fields.map(field => ({...field, style: field.style || 'LIST_REVIEW'})) :
                    node.extra_fields;

                node.formDefinition = extraFields?.length ? FormGroupService.parseFormDefinition(extraFields, this.api, this.tzDate, this.filterService) : null;
                extraFields?.forEach(field => {
                    if (blocks.every(block => field.key !== block.key)) blocks.push(field);
                });

                const billingCodeCategoryName = node.implicitBillingCategory ? node.implicitBillingCategory.name : '';
                this.taskTypesFlattened.push({...node as TaskType, level, billingCodeCategoryName, roles: node.roles});
                if (node.children) {
                    node.children.forEach(child => processFlattenedCategory(
                        child,
                        bcc,
                        level + 1,
                        node.implicitRoles,
                        node.implicitDuration,
                        node.implicitFax,
                        node.implicitSms,
                        node.implicitBillingCategory,
                        node.implicitBillingPlaceholderText,
                        node.implicitBillingNotePlaceholderText
                    ));
                }
            };
            res.forEach(category => processFlattenedCategory(category, bcc, null));

            function processMerged(cats: TaskTypeDefinition[] = res, ascendants = [], out = []): TaskTypeMerged[] {
                cats.forEach(c => {
                    const path = [...ascendants, c];
                    if (c.children) processMerged(c.children, path, out);
                    else out.push(Object.assign(c, {path: ascendants, label: path.map(x => x.name).join(' > '), category: path[0]?.name || c.name}));
                });
                return out;
            }

            this.taskTypesMerged = processMerged(res);
            this.taskTypesMergedOptions = this.taskTypesMerged.filter(x => x.implicitRoles ? this._user.isSuperAdmin || x.implicitRoles.some(y => this._user.roles.some(k => y === k)) : true);
            const acuteMerged = this.taskTypesMerged.find(x => x.key === 'VISIT_ACUTE');
            this.taskTypesMergedOptionsWAcute = this.taskTypesMergedOptions.includes(acuteMerged) ?
                this.taskTypesMergedOptions :
                [...this.taskTypesMergedOptions, acuteMerged];

            output.nested = res as TaskType[];
            this.taskTypesFlattened$.next(this.taskTypesFlattened);
            output.flattened = this.taskTypesFlattened;
            this.taskTypesMerged$.next(this.taskTypesMerged);
            output.merged = this.taskTypesMerged;
            this.taskTypesMergedOptions$.next(this.taskTypesMergedOptions);
            output.mergedOptions = this.taskTypesMergedOptions;
            this.taskTypesMergedOptionsWAcute$.next(this.taskTypesMergedOptionsWAcute);

            this.taskBlocks$.next(blocks);

            this.taskDynamicFields = [...this.taskTypesMergedOptions.reduce((acc, tt) =>
                tt.formDefinition?.fields?.reduce((acc, f) => acc.add(f.key), acc) || acc, new Set<string>()
            )];

            obs$.next(output);
            obs$.complete();
        });
        return obs$;
    }

    getTaskType(key: string): TaskTypeMerged {
        return this.taskTypesMerged ? this.taskTypesMerged.find(x => x.key === key) : undefined;
    }
}
